<template>
    <div class="shopPage" v-loading="loading">
        <Bread :direct="direct" :bgc="bgc"></Bread>
        <div class="type_area">
            <div style="height: 40px;background: #FFFFFF;display:flex;align-items:center;font-size: 14px;color: #333333;justify-content: space-between;">
                <div style="display:flex;align-items:center;margin-right:100px;width:420px;margin-left:10px;">
                    <el-checkbox style="margin-right:40px;" v-model="allChecked" @change="handleCheckAllChange">全选</el-checkbox>
                    <div style="">商品信息</div>
                </div>
                <div style="display:flex;align-items:center;flex:1;justify-content: space-around;text-align:center;">
                    <div style="width:200px;">单价</div>
                    <div style="width:200px;">数量</div>
                    <div style="width:100px;">金额</div>
                    <div style="width:200px;">操作</div>
                </div>
            </div>
            <div v-for="(item,index) in goodslist" :key="index">
                <div style="margin-top:20px;border:1px solid #EEEEEE;background: #fff;padding:10px;border-bottom:none;">
                    <el-checkbox @change="storeChecked(item.cartGoodsInfo,$event)" v-model="item.checked">{{ item.companyName }}</el-checkbox>
                    <div style="display:flex;align-items:center;text-align:center;justify-content: space-between;">
                        <div style="display:flex;align-items:center;flex:1;text-align:center;">
                            <div style="font-size: 16px;margin-right:20px;"><span style="vertical-align: middle;">商品总价：</span><span style="color:#F92B2B;font-weight: 600;vertical-align: middle;">￥{{item.totalPrice.toFixed(2)}}</span></div>
                            <div style="font-size: 16px;margin-right:20px;"> <el-tooltip effect="dark" placement="top">
                                    <div slot="content">
                                        <!-- <span v-for="(items,ind) in discountDetail" :key="ind" style="display:block;">
                                    {{items.preferPrice?'【'+items.productName+'】':''}}{{items.preferPrice?',立减'+items.preferPrice+'元':''}}<br v-if="items.preferPrice">
                                </span> -->
                                        <span v-for="(items,ind) in item.actNotice" :key="ind" style="display:block;">
                                            {{items.description}}
                                        </span>
                                    </div>
                                    <img style="vertical-align: middle;margin-right:5px; " :class="{'no-pointer':discountDetail.length===0}" src="../../assets/images/shopCar/explain.svg" alt="">
                                </el-tooltip> <span style="vertical-align: middle;">优惠金额：</span><span style="font-weight: 600;color:#F92B2B;vertical-align: middle;">￥{{item.preferPrice.toFixed(2)}}</span></div>
                            <div style="font-size: 16px;"><span style="vertical-align: middle;">应付金额：</span><span style="font-size: 16px;color: #F92B2B;font-weight: 600;vertical-align: middle;">￥{{item.realPrice.toFixed(2)}}</span></div>
                        </div>
                        <div @click="explain(item,index)" v-if="!item.isShow" style="width:100px;height:30px;line-height:30px;text-align:center;border:1px solid #1a9dff;color:#1a9dff;cursor:pointer;">点击展开</div>
                        <div @click="explain(item,index)" v-else style="width:100px;height:30px;line-height:30px;text-align:center;border:1px solid #1a9dff;color:#1a9dff;cursor:pointer;">点击收起</div>
                    </div>
                </div>
                <div v-if="item.cartGoodsInfo.length&&item.isShow" ref="shopCar" style="background: #FFFFFF; " :class="{'ispad':item.promotionGiftsSendInfo.length}">
                    <!-- <div style="display:flex;align-items:center;margin-bottom:20px;padding-top:20px;">
                    <div style="margin:0 10px;">
                        <el-checkbox v-model="checked"></el-checkbox>
                    </div>
                    <div>
                        <div style="font-weight: bold;font-size: 18px;color: #333333;">安徽华人健康股份有限公司</div>
                        <div style="font-size: 14px;color:#999999;">商品金额小计：<span style="color:#F92B2B;font-weight: 700;">￥29.1</span></div>
                    </div>
                </div> -->
                    <div v-for="(el,index) in item.cartGoodsInfo" :key="el.id" style="border: 1px solid #EEEEEE;padding: 0 0 10px;" :class="{'lastone':index!==item.cartGoodsInfo.length-1,'selected':el.cart.checked,}">
                        <div style="display:flex;align-items:center;">
                            <div style="display:flex;align-items:center;margin-right:110px;width:420px;">
                                <div style="margin:0 10px;">
                                    <el-checkbox v-model="el.cart.checked" @change="changeChecked(el.cart.id,$event)"></el-checkbox>
                                </div>
                                <div style="position:relative;margin-top: 10px;cursor: pointer;" @click="goDetail(el.goodsSingleInfo.goodsId)">
                                    <img style="width:130px;height:130px;" :src="el.goodsSingleInfo.thumbUrl" alt="">
                                    <img v-if="el.goodsSingleInfo.stock==0" style="width:130px;height:130px;position:absolute;top:0;left:0;" src="../../assets/images/shopCar/soonGet.png" alt="">
                                </div>
                                <div style="font-size: 14px;color: #999999;margin-left:10px;">
                                    <div style="display:flex;align-items:center;margin-bottom:12px;cursor: pointer;" @click="goDetail(el.goodsSingleInfo.goodsId)">
                                        <div class="tejia" v-if="el.cart.actType==2">特价</div>
                                        <div class="mz" v-if="el.cart.actType==4">买赠</div>
                                        <div style="font-weight: bold;font-size: 16px;color: #333333;">{{el.goodsSingleInfo.productName}}</div>
                                    </div>
                                    <div><span style="display:inline-block;width:30px;margin-right: 10px;">厂商</span> <span style="color: #333333;">{{el.goodsSingleInfo.produceUnit}}</span></div>
                                    <div v-if="el.goodsSingleInfo.isExpired"><span style="display:inline-block;width:30px;margin-right: 10px;">效期</span> <span style="color:#FF6A05;">优于{{el.goodsSingleInfo.lastDate}}(近效期)</span></div>
                                    <div v-else><span style="display:inline-block;width:30px;margin-right: 10px;">效期</span> <span style="color: #333333;">优于{{el.goodsSingleInfo.lastDate}}</span></div>
                                    <div style="overflow:hidden;white-space:nowrap;width:220px;text-overflow:ellipsis;"><span style="display:inline-block;width:30px;margin-right: 10px;">规格</span> <el-tooltip class="item" effect="dark" :disabled="el.goodsSingleInfo.norms.length<23" :content="el.goodsSingleInfo.norms" placement="top-start">
                                            <span style="color: #333333;">{{el.goodsSingleInfo.norms}}</span>
                                        </el-tooltip></div>
                                    <div><span style="display:inline-block;width:30px;margin-right: 10px;">库存</span> <span style="color: #333333;">{{el.goodsSingleInfo.stock}}</span></div>
                                </div>
                            </div>
                            <div style="display:flex;align-items:center;flex:1;justify-content: space-around;text-align: center;">
                                <div style="width:200px;">
                                    <div style="font-size: 18px;color: #F92B2B;">
                                        <div>￥{{(el.goodsSingleInfo.jiage).toFixed(2)}}
                                            <img v-if="el.priceFloatInfo.sign&&el.priceFloatInfo.diffType=='up'" style="width: 20px;height: 20px;vertical-align: sub;" src="../../assets/images/shopCar/up.svg" alt="">
                                            <img v-if="el.priceFloatInfo.sign&&el.priceFloatInfo.diffType=='down'" style="width: 20px;height: 20px;vertical-align: sub;" src="../../assets/images/shopCar/down.svg" alt="">
                                        </div>
                                        <div style="color:#333;font-size: 14px;text-decoration: line-through;" v-if="el.goodsSingleInfo.jiage!=el.goodsSingleInfo.price">￥{{(el.goodsSingleInfo.price).toFixed(2)}}</div>

                                    </div>
                                    <div v-if="el.priceFloatInfo.sign">
                                        <div style="font-size: 14px;" v-if="el.priceFloatInfo.diffType=='down'">较上次采购<span style="color:green;">降￥{{el.priceFloatInfo.diff}}</span></div>
                                        <div style="font-size: 14px;width:200px;" v-if="el.priceFloatInfo.diffType=='up'">较上次采购<span style="color:red;">涨￥{{el.priceFloatInfo.diff}}</span></div>
                                    </div>
                                </div>
                                <div style="text-align: center;width:200px;">
                                    <el-input-number style="width:140px;" @change="changeCount(el.cart.id,el.cart.quantity)" :step="el.goodsSingleInfo.step" v-model="el.cart.quantity" :min="1"></el-input-number>
                                    <div v-if="el.selectList&&el.selectList.length&&el.cart.useAct&&(el.goodsSingleInfo.bargain||(el.goodsSingleInfo.acts.length&&el.goodsSingleInfo.acts[0].actType==2)||(el.goodsSingleInfo.acts.length&&el.goodsSingleInfo.acts[0].actType==4))" style="font-size: 12px;color: #1A9DFF;">{{el.goodsSingleInfo.bargain||el.goodsSingleInfo.acts[0].description}}</div>
                                    <div style="font-size: 12px;color: #1A9DFF;" v-if="el.selectList&&el.selectList.length&&!el.cart.useAct">不参与优惠活动，不要赠品</div>
                                </div>
                                <div style="font-weight: bold;font-size: 18px;color: #F92B2B;width:100px;">￥{{(el.goodsSingleInfo.jiage*el.cart.quantity).toFixed(2)}}</div>
                                <div>
                                    <el-select v-if="el.selectList&&el.selectList.length" class="select" v-model="el.cart.useAct" @change="selectChange(el,$event)" placeholder="请选择">
                                        <el-option v-for="ite in el.selectList" :key="ite.id" :label="ite.name" :value="ite.id">
                                        </el-option>
                                    </el-select>
                                    <div style="display:flex;align-items:center;font-size: 14px;color: #666666;width:200px;justify-content: center;">
                                        <el-popconfirm title="确定删除吗？" @onConfirm="deleteCar(el.cart.id)">
                                            <div class="delete" style="cursor: pointer;" slot="reference">删除</div>
                                        </el-popconfirm>
                                        <div style="width: 0px;height: 12px;border: 1px solid #EEEEEE;margin:0 10px;"></div>
                                        <div class="collect" style="cursor: pointer;" @click="collect(el.goodsSingleInfo)">{{el.goodsSingleInfo.favorite?'取消收藏':'收藏'}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="donate" v-for="(ele,ind) in el.giftsSendInfo" :key="ind">
                            <div style="display:flex;align-items:center;margin-right:100px;margin-left:10px;"><img src="../../assets/images/shopCar/donate.svg" alt="">
                                <div style="margin-left:10px;width:100px;">【赠品】</div>
                            </div>
                            <div style="width:300px;">{{ele.goodsSingleInfo.productName}}</div>
                            <div style="width:300px;">优于{{ele.goodsSingleInfo.lastDate}}</div>
                            <div style="width:200px;">{{ele.goodsSingleInfo.norms}}</div>
                            <div style="width:300px;">{{ele.cart.quantity}}{{ele.goodsSingleInfo.unit}}</div>
                            <div style="width:18px;height:18px;"><img src="../../assets/images/orderInfo/yellowright.svg" alt=""></div>
                        </div>
                    </div>
                    <div class="donate" style="margin-top:10px;" v-for="(els,indexs) in item.promotionGiftsSendInfo" :key="indexs">
                        <div style="display:flex;align-items:center;margin-right:100px;margin-left:10px;"><img src="../../assets/images/shopCar/donate.svg" alt="">
                            <div style="margin-left:10px;width:100px;">【赠品】</div>
                        </div>
                        <div style="width:300px;">{{els.goodsSingleInfo.productName}}</div>
                        <div style="width:300px;">优于{{els.goodsSingleInfo.lastDate}}</div>
                        <div style="width:200px;">{{els.goodsSingleInfo.norms}}</div>
                        <div style="width:300px;">{{els.cart.quantity}}{{els.goodsSingleInfo.unit}}</div>
                        <div style="width:18px;height:18px;"><img src="../../assets/images/orderInfo/yellowright.svg" alt=""></div>
                    </div>
                </div>
                <div v-if="!item.cartGoodsInfo.length&&item.isShow">
                    <div class="nodata_box">
                        <img src="@/assets/images/index/nodata.png" alt="">
                        <span>暂无数据哦~</span>
                    </div>
                </div>
            </div>
            <div ref="carPay" class="car-pay" style="height: 66px;z-index:99;width:1200px;background: #FFFFFF;display:flex;align-items:center;font-size: 14px;color: #333333;justify-content: space-between;margin-top:10px;">
                <div style="display:flex;align-items:center;margin-right:100px;width:400px;margin-left:10px;">
                    <el-checkbox style="margin-right:40px;" v-model="allChecked" @change="handleCheckAllChange">全选</el-checkbox>
                    <div style="display:flex;align-items:center;font-size: 14px;color: #666666;width:200px;justify-content: center;">
                        <el-popconfirm title="确定删除吗？" @onConfirm="deleteCar('checked')">
                            <div style="cursor: pointer;" slot="reference">删除</div>
                        </el-popconfirm>
                        <div style="width: 0px;height: 12px;border: 1px solid #EEEEEE;margin:0 10px;"></div>

                        <el-popconfirm title="确定清空采购车吗？" @onConfirm="clearCar">
                            <div style="cursor: pointer;" slot="reference">清空采购车</div>
                        </el-popconfirm>
                    </div>
                </div>
                <div style="display:flex;align-items:center;flex:1;justify-content: space-between;text-align:center;">
                    <div style="font-size: 16px;"><span style="vertical-align: middle;">商品总价：</span><span style="color:#F92B2B;font-weight: 600;vertical-align: middle;">￥{{totalPrice.toFixed(2)}}</span></div>
                    <div style="font-size: 16px;"> <el-tooltip effect="dark" placement="top">
                            <div slot="content">
                                <span v-for="(items,ind) in discountDetail" :key="ind" style="display:block;">
                                    {{ items.description }}
                                </span>
                            </div>
                            <img style="vertical-align: middle;margin-right:5px; " :class="{'no-pointer':discountDetail.length===0}" src="../../assets/images/shopCar/explain.svg" alt="">
                        </el-tooltip> <span style="vertical-align: middle;">优惠金额：</span><span style="font-weight: 600;color:#F92B2B;vertical-align: middle;">￥{{preferPrice.toFixed(2)}}</span></div>
                    <div style="font-size: 16px;"><span style="vertical-align: middle;">应付金额：</span><span style="font-size: 22px;color: #F92B2B;font-weight: 700;vertical-align: middle;">￥{{realPrice.toFixed(2)}}</span></div>
                    <div style="width: 155px;height: 66px;background: #1A9DFF;font-weight: bold;font-size: 22px;color: #FFFFFF;text-align: center;line-height: 66px;cursor: pointer;" @click="goPay">去结算</div>
                </div>
            </div>
        </div>
        <el-dialog :visible="noticeVisible" center="center" title="提示" width="22%" class="else-dialog" @close="noticeclosed">
            <div v-for="(item,index) in notice" :key="index">
                <div style="font-size:16px;margin-bottom:10px;">{{item.companyName}}</div>
                <div v-for="(items,indexs) in item.cartNoticeResp" :key="indexs" class="sketch_content" style="font-size:16px;margin-bottom:10px;">
                    <div style="color:red;font-weight:600">
                        <span>{{items.message}}</span>
                    </div>
                    <div v-for="(el,ind) in items.goodsInfo" :key="ind">
                        <span>{{ind+1}}.</span><span>{{el}}</span>
                    </div>
                </div>
            </div>

        </el-dialog>
        <el-dialog title="" :visible.sync="dateDialog" width="40%" @close="handleClose">
            <div style=" margin-bottom:30px;" v-if="dateArr.length">
                <div style="font-size: 18px;color: #303133;margin-bottom:10px;">
                    <i class="el-icon-warning" style="color:#faad14;"></i> 所选商品有近效期商品
                </div>
                <div v-for="(item,index) in dateArr" :key="index" style="margin-bottom:10px;color:#FF6A05;font-size:16px;">
                    <span>{{index+1}}.{{ item.company }} </span>
                    <span style="color:#333;">{{item.goods.goodsSingleInfo.productName}}</span>
                    优于{{item.goods.goodsSingleInfo.lastDate}}（近效期）
                </div>
            </div>
            <div v-if="floatArr.length">
                <div style="font-size: 18px;color: #303133;margin-bottom:10px;"> <i class="el-icon-warning" style="color:#faad14;"></i> 所选商品中有价格变动商品</div>
                <div v-for="(item,index) in floatArr" :key="index" style="margin-bottom:10px;color:#FF6A05;font-size:16px;">
                    <span>{{index+1}}.{{ item.company }} </span>
                    <span style="color:#333;">{{item.goods.priceFloatInfo.productName}} 较上次采购
                        <span v-if="item.goods.priceFloatInfo.diffType=='down'" style="color:green;">降￥{{item.goods.priceFloatInfo.diff}}</span>
                        <span v-if="item.goods.priceFloatInfo.diffType=='up'" style="color:#FF6A05;">涨￥{{item.goods.priceFloatInfo.diff}}</span>

                    </span>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dateDialog = false">重选商品</el-button>
                <el-button type="primary" @click="payContune">继续下单</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import Bread from '@/components/Bread'
import scrollMixin from '@/minix/scrollEvent'
import { getCarNum } from '@/utils/carNumber'
export default {
    components: {
        Bread
    },
    mixins: [scrollMixin],
    data() {
        return {
            direct: ['首页', '采购车'],
            bgc: '#f3f3f3',
            preferPrice: '',
            realPrice: '',
            totalPrice: '',
            list: [


            ],
            allChecked: false,

            discountDetail: [],
            offsetTop: 0,
            shopHeight: 0,
            promotionGiftsSendInfo: [],
            noticeVisible: false,
            isShow: false,
            notice: [],
            dateDialog: false,
            loading: false,
            dateArr: [],
            floatArr: [],
            goodslist: [


            ],
        }
    },
    async created() {
        await this.getShopCar()
        this.update()
    },
    mounted() {
        // setInterval(() => {
        //     this.update()
        // }, 200);
    },
    // updated() {
    //     this.update()
    // },
    // computed: {
    //     allChecked: {
    //         get() {
    //             if (this.goodslist.length == 0) return false
    //             return this.goodslist.every(item => item.checked)
    //         },
    //         set(val) {
    //             this.goodslist.forEach(item => {
    //                 item.checked = val
    //             })
    //         }
    //     }
    // },
    methods: {
        update() {
            this.isShow = !this.isShow
            this.$nextTick(() => {
                console.log(this.$refs.carPay.offsetTop);
                this.offsetTop = this.$refs.carPay.offsetTop
                this.shopHeight = this.$refs.shopCar.offsetHeight
                if (document.documentElement.scrollTop + window.innerHeight >= this.offsetTop) {
                    document.querySelector('.car-pay').style.position = 'relative'
                    document.querySelector('.car-pay').style.boxShadow = '0px 0px 0px 0px rgba(0, 0, 0, 0)'
                } else {
                    document.querySelector('.car-pay').style.position = 'fixed'
                    document.querySelector('.car-pay').style.bottom = '0'
                    document.querySelector('.car-pay').style.boxShadow = '0px -10px 10px 0px rgba(0, 0, 0, 0.1)'
                }

                if (this.offsetTop == 853 && this.isShow) {
                    this.update()
                }
            })
        },
        noticeclosed() {
            this.noticeVisible = false
        },

        handleClose() {

            this.dateArr = []
            this.floatArr = []
            this.dateDialog = false
        },
        explain(item, index) {
            // this.isShow=!this.isShow
            item.isShow = !item.isShow
            sessionStorage.setItem('explain' + index, item.isShow)
            setTimeout(() => {
                this.update()
            }, 200);

        },
        payContune() {
            this.dateDialog = false
            this.$router.push('/orderInfo')
        },
        //单选
        async changeChecked(id, val) {
            console.log(id, val);
            await this.$orderApis.multipleCartSelect({
                cartIds: [id],
                checked: val ? 1 : 0,
                allChecked: 0
            })
            this.getShopCar()
            // this.update()
        },
        async storeChecked(arr, val) {
            console.log(arr, val);
            const ids = arr.map(item => item.cart.id)
            await this.$orderApis.multipleCartSelect({
                cartIds: ids,
                checked: val ? 1 : 0,
                allChecked: 0
            })
            this.getShopCar()
            // this.update()
        },
        async handleCheckAllChange(val) {
            console.log(val);
            await this.$orderApis.multipleCartSelect({
                cartIds: [],
                checked: 1,
                allChecked: val ? 1 : -1
            })
            this.getShopCar()
            // this.update()
        },
        //数量改变
        async changeCount(id, count) {
            await this.$orderApis.changeNum({
                cartId: id,
                num: count
            })
            this.getShopCar()
            // this.update()
        },
        async selectChange(el) {
            console.log(el);
            const res = await this.$orderApis.giveSelect({
                cartId: el.cart.id,
                useAct: el.cart.useAct
            })
            if (res.data.code == 200) {
                this.getShopCar()
            }
        },
        //详情
        async getShopCar() {
            this.loading = true
            getCarNum(this)
            const res = await this.$orderApis.multipleCartList()
            if (res.data.code == 200) {
                this.loading = false
                res.data.data.cartInfo.forEach((item, index) => {
                    if (index == 0) {
                        item.isShow = sessionStorage.getItem('explain' + index) == null || sessionStorage.getItem('explain' + index) == undefined ? true : JSON.parse(sessionStorage.getItem('explain' + index))
                    } else {
                        item.isShow = sessionStorage.getItem('explain' + index) == null || sessionStorage.getItem('explain' + index) == undefined ? false : JSON.parse(sessionStorage.getItem('explain' + index))
                    }
                    if (item.checked) {
                        item.checked = true
                    } else {
                        item.checked = false
                    }
                    item.cartGoodsInfo.forEach((item1) => {
                        if (item1.goodsSingleInfo.acts.length && item1.goodsSingleInfo.acts[0].actType == 4) {
                            item1.selectList = [{
                                name: item1.goodsSingleInfo.acts[0].description,
                                id: 1
                            },
                            {
                                name: '不参与优惠活动，不要赠品',
                                id: 0
                            }
                            ]

                        }
                        item1.goodsSingleInfo.Pack.forEach(items => {
                            if (items.isPack == 'on') {
                                item1.goodsSingleInfo.step = +items.num
                            } else {
                                item1.goodsSingleInfo.step = +item1.goodsSingleInfo.Pack[0].num
                            }
                        })
                        if (item1.cart.checked) {
                            item1.cart.checked = true
                        } else {
                            item1.cart.checked = false
                        }
                    })

                })
                this.goodslist = res.data.data.cartInfo
                this.discountDetail = res.data.data.actNotice
                this.allChecked = res.data.data.allChecked ? true : false
                console.log(this.goodslist, 's');
                // this.list = res.data.data.cartInfo.cartGoodsInfo
                // this.list.forEach(item => {
                //     item.goodsSingleInfo.Pack.forEach(items => {
                //         if (items.isPack == 'on') {
                //             item.goodsSingleInfo.step = +items.num
                //         } else {
                //             item.goodsSingleInfo.step = +item.goodsSingleInfo.Pack[0].num
                //         }
                //     })


                //     if (item.cart.checked) {
                //         item.cart.checked = true
                //     } else {
                //         item.cart.checked = false
                //     }
                // })
                // res.data.data.cartInfo.cartActInfo.forEach((item) => {
                //     if (item.actType != 4) {
                //         item.goodsInfo.forEach(el => {
                //             this.discountDetail.push(el)
                //         })
                //     }
                // })
                this.preferPrice = res.data.data.preferPrice
                this.realPrice = res.data.data.realPrice
                this.totalPrice = res.data.data.totalPrice
                // this.promotionGiftsSendInfo = res.data.data.cartInfo.promotionGiftsSendInfo
                this.notice = res.data.data.multipleCartNotice
                if (this.notice.length > 0) {
                    this.noticeVisible = true
                }
            }
        },
        //点击收藏
        async collect(item) {
            const res = await this.$orderApis.collect({
                goodsId: item.goodsId
            })
            if (res.data.code !== 200) return
            item.favorite = !item.favorite
            item.favorite ? this.$message({
                message: '收藏成功',
                type: "success"
            }) : this.$message('取消收藏成功')
            // this.update()
        },
        async clearCar() {
            const res = await this.$orderApis.deleteCart(
                {
                    clearable: 1,
                    cartId: []
                }
            )
            if (res.data.code !== 200) return
            this.$message({
                type: 'success', // success error warning
                message: '清空成功'
            })
            this.getShopCar()
        },
        async deleteCar(id) {
            if (id == 'checked') {
                console.log(this.goodslist);
                const cartId = []
                const checked = this.goodslist.filter(item => item.checked)
                checked.forEach(item => {
                    item.cartGoodsInfo.forEach(item1 => {
                        if (item1.cart.checked) {
                            cartId.push(item1.cart.id)
                        }
                    })
                })

                console.log(checked);
                const res = await this.$orderApis.deleteCart(
                    {
                        clearable: 0,
                        cartId: cartId
                    }
                )
                if (res.data.code !== 200) return
            } else {
                const res = await this.$orderApis.deleteCart(
                    {
                        clearable: 0,
                        cartId: [id]
                    }
                )
                if (res.data.code !== 200) return
            }

            this.$message({
                type: 'success', // success error warning
                message: '删除成功'
            })
            this.getShopCar()

        },
        goPay() {
            // const checked = this.goodslist.filter(item => item.cart.checked)
            // const cartId = []
            const checked = this.goodslist.filter(item => item.checked)

            if (checked.length === 0) return this.$message({
                type: 'warning', // success error warning
                message: '请选择商品'
            })
            checked.forEach(item => {
                item.cartGoodsInfo.forEach(item1 => {
                    if (item1.goodsSingleInfo.isExpired == 1 && item1.cart.checked) {
                        this.dateArr.push({ company: item.companyName, goods: item1 })
                    }
                    if (item1.priceFloatInfo.sign && item1.cart.checked) {
                        this.floatArr.push({ company: item.companyName, goods: item1 })
                    }
                })
            })

            // this.dateArr =Array.from(new Set(this.dateArr))
            // this.floatArr =Array.from(new Set(this.floatArr))


            // this.dateArr = checked.filter((item) => {
            //     return item.goodsSingleInfo.isExpired === 1 
            // })
            // this.floatArr = checked.filter((item) => {
            //     return item.priceFloatInfo.sign 
            // })
            console.log(this.dateArr, this.floatArr);
            if (this.dateArr.length || this.floatArr.length) {
                this.dateDialog = true
            } else {
                this.$router.push('/orderInfo')
            }
            // this.$router.push('/orderInfo')
        },
        goDetail(id) {
            window.open(`${this.baseURL}/hr/#/goodsDetail?goodsId=${id}`)
        },

    },

}
</script>

<style lang="scss" scoped>
.ispad {
    padding-bottom: 10px;
}
.no-pointer {
    pointer-events: none;
}
.select {
    width: 150px;
    height: 60px;
}
.sketch_content {
    // 必须有高度 overflow 为自动
    overflow: auto;
    max-height: 400px;
    border-top: 1px solid #eff1f4;
    border-bottom: 1px solid #eff1f4;
    padding: 0px 30px 11px 27px;

    // 滚动条的样式,宽高分别对应横竖滚动条的尺寸
    &::-webkit-scrollbar {
        width: 3px;
    }

    // 滚动条里面默认的小方块,自定义样式
    &::-webkit-scrollbar-thumb {
        background: #8798af;
        border-radius: 2px;
    }

    // 滚动条里面的轨道
    &::-webkit-scrollbar-track {
        background: transparent;
    }
}
.tejia {
    width: 30px;
    height: 17px;
    background: #fae5e5;
    border-radius: 2px 2px 2px 2px;
    border: 1px solid #eeeeee;
    font-weight: bold;
    font-size: 10px;
    color: #f92b2b;
    line-height: 17px;
    text-align: center;
    box-sizing: border-box;
    margin-right: 10px;
}
.mz {
    width: 30px;
    height: 17px;
    // background: #fae5e5;
    border-radius: 2px 2px 2px 2px;
    // border: 1px solid #eeeeee;
    font-weight: bold;
    font-size: 10px;
    background: rgba(248, 240, 223, 0.9);
    border: 1px solid #fad894;
    color: #f7a400;
    line-height: 17px;
    text-align: center;
    box-sizing: border-box;
    margin-right: 10px;
}
.selected {
    background: #f5fbff;
}
.lastone {
    border-bottom: none !important;
}
.donate {
    // height: 42px;
    padding: 10px 0px;
    background: #fef9f1;
    border-radius: 4px;
    border: 1px solid #f8f2e7;
    margin: 0px 50px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #7a5611;
}
::v-deep.el-input-number .el-input__inner {
    font-weight: bold !important;
    font-size: 16px !important;
    color: #333333 !important;
}

.delete {
    &:hover {
        color: #999;
    }
}
.collect {
    &:hover {
        color: #999;
    }
}
</style>
<style lang="scss">
.else-dialog {
    .el-dialog__header {
        .el-dialog__title {
            font-weight: bold !important;
            font-size: 25px !important;
            color: #1a1a1a !important;
        }
    }

    .el-dialog {
        border-radius: 10px 10px 10px 10px !important;
    }
}
</style>